import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path {...props} fillRule="evenodd" clipRule="evenodd" d="M0.425042 0.518955C-0.03057 0.974566 -0.03057 1.71326 0.425042 2.16887L3.68342 5.42725L0.425042 8.68562C-0.03057 9.14123 -0.03057 9.87993 0.425042 10.3355C0.880653 10.7911 1.61935 10.7911 2.07496 10.3355L6.15829 6.2522C6.6139 5.79659 6.6139 5.0579 6.15829 4.60229L2.07496 0.518955C1.61935 0.0633432 0.880653 0.0633432 0.425042 0.518955Z" fill="white" />
        </svg>
    );
};

export default Icon;