import { ArrowRightIcon, Flex, Link, Text } from '@pancakeswap/uikit'
import React from 'react'
import styled from 'styled-components'

const Item = ({ item }) => {
    return (
        <WrapItem background={item?.img}>
            <div>
                <CsSubImg src={item?.imgSub} alt='' />
                <CsTitle> {item?.title} </CsTitle>
                <CsSubTitle> {item?.subTitle} </CsSubTitle>
            </div>
            <Flex width="100%" justifyContent="space-between">
                <Text fontSize="14px" color={item?.color}>Learn more</Text>
                <ArrowRightIcon />
            </Flex>
        </WrapItem>
    )
}

const WrapItem = styled(Flex) <{ background: string }>`
    max-width: 400px;
    height: 100%;
    min-height: 380px;
    border-radius: 10px;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: url(${({ background }) => background}) center top / 100% 100% no-repeat;
    padding: 3rem 2rem;
    position: relative;
    @media screen and (max-width: 1024px){
      padding: 2rem 2rem;
    }
`
const CsTitle = styled(Text)`
    font-size: 28px;
    margin-top: 80px;
    line-height: 38px;
    font-weight: 900;
    letter-spacing: -0.02em;
    color: ${({ theme }) => theme.colors.title};
    @media screen and (max-width: 1024px){
        font-size: 24px;
    }
`
const CsSubTitle = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  margin-top: 16px;
  @media screen and (max-width: 1024px){
        font-size: 13px;
    }
`
const CsSubImg = styled.img`
  position: absolute;
  top: -120px;
  left: 50%;
  width: 240px;
  height: 240px;
  transform: translatex(-50%);
`
const CsLink = styled(Link)`
  cursor: pointer;
`
export default Item