import { ArrowRightIcon, Flex, Link, Text } from '@pancakeswap/uikit'
import React from 'react'
import styled from 'styled-components'

const ItemSection6 = ({ item }) => {
    return (
        <WrapItem background={item?.img}>
            <CsImgLeft src='/images/base_img/section6_2.png?version=1.3' alt='' />
            <div>
                <CsTitle> {item?.title} </CsTitle>
                <CsSubTitle> {item?.subTitle} </CsSubTitle>
            </div>
            <Flex width="100%" justifyContent="flex-start">
                <Flex width="fit-content" backgroundColor={item?.background} borderRadius="50px">
                    <Text letterSpacing="1.5px" padding="10px" fontSize="14px" color={item?.color} fontWeight="700">ONLINE</Text>
                </Flex>
            </Flex>
        </WrapItem>
    )
}

const WrapItem = styled(Flex) <{ background: string }>`
    max-width: 400px;
    height: 100%;
    min-height: 380px;
    border-radius: 10px;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: url(${({ background }) => background}) center top / 100% 100% no-repeat;
    padding: 3rem 2rem;
    position: relative;
    @media screen and (max-width: 1024px){
      padding: 2rem 2rem;
      margin-bottom: 2rem;
      width: 100%;
    }
`
const CsTitle = styled(Text)`
    font-size: 28px;
    margin-top: 80px;
    line-height: 38px;
    font-weight: 900;
    letter-spacing: -0.02em;
    color: ${({ theme }) => theme.colors.title};
    @media screen and (max-width: 1024px){
        font-size: 20px;
    }
`
const CsSubTitle = styled(Text)`
  font-weight: 400;
  font-size: 14px;
  margin-top: 16px;
  @media screen and (max-width: 1024px){
        font-size: 13px;
    }
`
const CsImgLeft = styled.img`
    position: absolute;
    top: 32px;
    left: 32px;
    width: 32px;
    object-fit: contain;
`
export default ItemSection6