import { ChainId } from '@pancakeswap/sdk'
import { CHAIN_IDS, SUPPORT_CHAIN_CLOUDBASE } from 'utils/wagmi'
import Home from 'views/HomeView/Home'

const IndexPage = () => {
  return <Home />
}
IndexPage.chains = SUPPORT_CHAIN_CLOUDBASE

export default IndexPage
