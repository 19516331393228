import { Flex } from '@pancakeswap/uikit'
import React from 'react'
import styled from 'styled-components'
import { Container, CsHeaderSection, CsWrapItem, WrapListItem } from '../styles'
import ItemSection6 from './ItemSection6'

const dataSection6 = [
    {
        id: 1,
        img: '/images/base_img/threeBig.png',
        title: 'All Base Assets/DApp supported',
        subTitle: 'And more to be added to the platform',
        link: '',
        color: '#89EB5B',
        background: 'rgba(137, 235, 91, 0.05)',
    },
    {
        id: 2,
        img: '/images/base_img/oneBig.png',
        title: '$19.11M Liquidity',
        subTitle: 'Used and trusted globally',
        link: '',
        color: '#6F5BEB',
        background: 'rgba(111, 91, 235, 0.05)',
    },
    {
        id: 3,
        img: '/images/base_img/twoBig.png',
        title: '2M+ transactions',
        subTitle: 'Secured by a permissionless system',
        link: '',
        color: '#FFC600',
        background: 'rgba(255, 198, 0, 0.05)',
    }
]

const Section6 = () => {

    return (
        <>
            {/* <CsCountdownTransition>
                <img src='/images/base_img/awan-malam.png?version=1.2' alt='' />
                <div className='item-countdown'>
                    <div>
                        <h1>220K+</h1>
                        <p>Contract creators</p>
                    </div>
                    <div>
                        <h1>$110M</h1>
                        <p>Avg. daily gas saved</p>
                    </div>
                    <div>
                        <h1>89</h1>
                        <p>Active validators</p>
                    </div>
                    <div>
                        <h1>90M+</h1>
                        <p>Unique addresses</p>
                    </div>
                    <div>
                        <h1>17.5K</h1>
                        <p>Delegators on PoS</p>
                    </div>
                    <div>
                        <h1>4.9B+</h1>
                        <p>Total transactions</p>
                    </div>
                </div>
            </CsCountdownTransition> */}
            <Container>
                <CsHeaderSection>STATISTIC</CsHeaderSection>
                <WrapListItem>
                    {dataSection6?.map((item) => {
                        return (
                            <CsWrapItem>
                                <ItemSection6 item={item} />
                            </CsWrapItem>
                        )
                    })}
                </WrapListItem>
                <CsImgRight src='/images/base_img/section6.png?version=1.2' alt='' />
            </Container>
        </>
    )
}

const CsCountdownTransition = styled.div`
    max-width: 1320px;
    padding: 0 20px;
    margin: auto;
    width: 100%;
    border-radius: 16px;
    background: #0081E2;
    margin-bottom: 265px;
    display: flex;
    align-items: center;
    gap: 30px;
    padding: 63px 0px 63px 130px;
    position: relative;
    .item-countdown{
        display: flex;
        align-items: center;
        gap: 30px;
        @media screen and (max-width: 1024px){
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            width: 100%;
        }

    }
    @media screen and (max-width: 1024px){
        padding: 22px 36px;
        width: 100%;
        margin-bottom: 52px;
    }
    img{
        width: 150px;
        height: 150px;
        position: absolute;
        top: 50%;
        left: 30px;
        @media screen and (max-width: 1024px){
            display: none;
        }
    }
    h1{
        color: #FFF;
        font-size: 32px;
        font-style: normal;
        font-weight: 900;
        line-height: 38.4px;
        letter-spacing: -0.64px;
    }
    p{
        color: #62FFD9;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: -0.36px;
    }
`


const CsImgRight = styled.img`
    position: absolute;
    top: -200px;
    right: -300px;
    width: 500px;
    object-fit: contain;
    z-index: 1;
    @media screen and (max-width: 1024px){
      right: -300px;
      top: -200px;
    }
    @media screen and (max-width: 768px){
      width: 300px;
      right: -200px;
      top: -100px;
    }
`
export default Section6