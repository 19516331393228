import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

import { FreeMode, Pagination } from 'swiper/modules';


import Item from './Item'
import { Container, CsHeaderSection, CsOurPartners, CsWrapItem, WrapListItem } from '../styles'

const data = [
    {
        id: 1,
        img: '/images/base_img/oneBig.png',
        imgSub: '/images/base_img/one.png',
        title: 'Trade Anything',
        subTitle: 'Trade any token on BASE in seconds, just by connecting your wallet.',
        link: '',
        color: '#6F5BEB',
    },
    {
        id: 2,
        img: '/images/base_img/twoBig.png',
        imgSub: '/images/base_img/two.png',
        title: 'Earn passive income',
        subTitle: 'CloudBase makes it easy to make your crypto work for you. Let your Clouds work for you.',
        link: '',
        color: '#FFC600',
    },
    {
        id: 3,
        img: '/images/base_img/threeBig.png',
        imgSub: '/images/base_img/three.png',
        title: 'AMM NFT Marketplace',
        subTitle: 'AMM NFT Marketplace allows users to trade NFTs in Liquidity Pool thereby fostering more Defi to NFTs and enabling easy trading of assets.',
        link: '',
        color: '#89EB5B',
    }
];


const Section2 = () => {

    return (
        <Container>
            <OurPartners />
            <CsHeaderSection>Why CloudBase</CsHeaderSection>
            <WrapListItem>
                {data?.map((item) => {
                    return (
                        <CsWrapItem>
                            <Item item={item} />
                        </CsWrapItem>
                    )
                })}
            </WrapListItem>
        </Container>
    )
}

export default Section2

const OurPartners = () => {

    return (
        <CsOurPartners>
            <h4>Our Partners</h4>
            <h6>As a partner, it is important to have a positive experience using the website or app</h6>
            <Swiper
                slidesPerView={4}
                spaceBetween={6}
                freeMode
                pagination={{
                    clickable: true,
                }}
                modules={[FreeMode, Pagination]}
                className="mySwiper"
            >
                {
                    [...Array(11)].map((_, index) => (
                        <SwiperSlide key={`${index.toString()}`}>
                            <img src={`images/base_img/partners${index + 1}.png`} alt="" />
                        </SwiperSlide>
                    ))
                }
            </Swiper>
            <div className="wrapper-img">
                {
                    [...Array(11)].map((_, index) => (
                        <img src={`images/base_img/partners${index + 1}.png`} alt="" key={`${index.toString()}`} />
                    ))
                }
            </div>
        </CsOurPartners>
    )
}