import React from 'react'
import Section1 from './components/Section1'
import Section2 from './components/Section2'
import Section3 from './components/Section3'
import Section4 from './components/Section4'
import Section6 from './components/Section6'
import Section7 from './components/Section7'
import { CustomPage } from './styles'

const Home: React.FC<React.PropsWithChildren> = () => {
    return (
        <CustomPage>
            <Section1 />
            <Section2 />
            <Section3 />
            <Section4 />
            <Section6 />
            <Section7 />
        </CustomPage>
    )
}

export default Home