import { Flex, Link, Text } from "@pancakeswap/uikit";
import ConnectWalletButton from "components/ConnectWalletButton";
import Page from "components/Layout/Page";
import styled from "styled-components";

export const CustomPage = styled(Page)`
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    background: ${({ theme }) => theme.colors.main};
    display: flex;
    flex-direction:column;
    justify-content: center;
`
export const Container = styled.div`
    margin: auto;
    max-width: 1320px;
    position: relative;
    @media screen and (max-width: 768px){
        margin: 0;
    }
`
export const Wrapper = styled(Flex)`
    width: 100%;
    flex-wrap: wrap;
    min-height: 680px;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 600px){
      min-height: 400px;
    }
`

export const CsLeftHero = styled(Flex)`
    position: absolute;
    max-width: 60%;
    margin-left: 110px;
    grid-gap: 20px;
    width: 60%;
    gap: 20px;
    top: 5%;
    flex-direction: column;
    @media screen and (max-width: 768px){
      max-width: 80%;
      width: 100%;
      margin-left: 0;
      gap: 10px;
      margin-top:-200px;
      top: auto;
    }
    @media screen and (max-width: 600px){
      max-width: 90%;
    }
`
export const CsContentLeft = styled(Flex)`
    flex-direction: column;
    align-items: left;
    margin-bottom: 20px;
    @media screen and (max-width: 768px){
      margin-bottom: 10px;
    }
    ${Text}{
      color: ${({ theme }) => theme.colors.white};
        &:first-child{
          font-size: 18px;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          @media screen and (max-width: 768px){
            font-size: 14px;
          }
        }
        &:nth-child(2){
          font-size: 80px;
          font-weight: 900;
          line-height: normal;
          @media screen and (max-width: 768px){
            font-size: 24px;
            line-height: 1.5;
          }
        };
        &:nth-child(3){
          font-size: 24px;
          @media screen and (max-width: 768px){
            font-size: 14px;
          }
        }
    }
`

export const CsRightImg = styled(Flex)`
    width: 100%;
    justify-content: flex-end;
    img{
        height: auto;
        max-width: 55%;
        margin-top: 0;
        margin-right: -10px;
        @media screen and (max-width: 600px){
            max-width: 130%;
            width: 130%;
            margin-top: 0;
            margin-right: -245px;
        }
    }
`
export const CustomButtonConnectWallet = styled(ConnectWalletButton)`
    height: 48px;
    padding: 0 20px;
    text-transform: uppercase;
    padding: 2px 24px;
    background: #0081e2;
    color: ${({ theme }) => theme.colors.disable};
    border: 2px solid ${({ theme }) => theme.colors.white};
    cursor: pointer;
    font-size: 14px;
    @media screen and (max-width: 768px){
      font-size: 10px;
      height: 32px;
      padding: 0 5px;
}
`

export const CsLink = styled(Link)`
  font-size: 14px;
  font-weight: 500;
  margin-left: 32px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
  @media screen and (max-width: 768px){
    margin-left: 16px;
    font-weight: 700;
    font-size: 10px;
  }
  &:hover{
    text-decoration: none;
  }
`
export const CsCrollDown = styled.div`

`
export const CsTextCroll = styled(Text)`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.white};
`

export const CsHeaderSection = styled(Text)`
  width: 100%;
  font-size: 60px;
  font-weight: 900;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.white};
  @media screen and (max-width: 768px){
    font-size: 24px;
  }
`
export const WrapListItem = styled(Flex)`
  align-items: center;
  max-width: 1320px;
  margin-top: 64px;
  flex-wrap: wrap;
  gap: 4rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media screen and (max-width: 1000px){
    justify-content: center;
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 600px){
    grid-template-columns: repeat(1, 1fr);
    place-items: center;
  }
`
export const CsWrapItem = styled.div`
    @media screen and (max-width: 1024px){
        width: 100%;
        display: flex;
        justify-content: center;
      }
`
export const CsOurPartners = styled.div`
    margin-bottom: 137px;
    max-width: 1320px;
    @media screen and (max-width: 1024px){
        margin-bottom: 150px;
        }
    h4{
        color: #FFF;
        font-size: 60px;
        font-style: normal;
        font-weight: 900;
        line-height: 88px;
        @media screen and (max-width: 1024px){
            text-align: center;
            font-size: 24px;
        }
    }
    h6{
        color: #FFF;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
        max-width: 630px;
        width: 100%;
        display: flex;
        @media screen and (max-width: 1024px){
            color: #FFF;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            max-width: 300px;
            margin: auto;
        }
    }
    .mySwiper{
        margin-top: 52px;
        display: none;
        max-width: 1320px;
        padding: 0 20px;
        margin: auto;
        @media screen and (max-width: 1024px){
            display: block;
            position: absolute;
            left: -20px;
            max-width: 100%;
            margin-top: 24px;
        }
        .swiper-pagination{
            display: none !important;
        }
    }
    .wrapper-img{
        justify-content: center;
        display: flex;
        gap: 84px;
        flex-flow: wrap;
        margin-top: 52px;
        @media screen and (max-width: 1024px){
            display: none;
        }
    }
    img{
        width: 150px;
        height: 150px;
        @media screen and (max-width: 1024px){
            border-radius: 12px;
            width: 80px;
            height: 80px;
        }
    }
`

export const CsScroll = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 768px){
        width: 150px;
        margin-bottom: 0;
        bottom: 200px;
        position: relative;
        height: 0;
    }
  img{
    width: 24px;
    height: 48px;
  }
  p{
    @media screen and (max-width: 768px){
        font-size: 13px;
    }
    color: #FFF;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.6px;
    margin-top: 24px;
  }
`