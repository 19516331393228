import { Flex, Text } from '@pancakeswap/uikit'
import React from 'react'
import GetWindowSize from '@pancakeswap/uikit/src/util/getWindowSize'
import useAuth from 'hooks/useAuth'
import { useSessionChainId } from 'hooks/useSessionChainId'
import { useAccount } from 'wagmi'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { Wrapper, CsLeftHero, CsContentLeft, CustomButtonConnectWallet, CsLink, CsRightImg, CsScroll } from '../styles'

const Section1 = () => {
  const windownSize = GetWindowSize();
  const { isConnected } = useAccount()
  const { logout } = useAuth()
  const [, setSessionChainId] = useSessionChainId()
  const { chainId } = useActiveWeb3React()
  return (
        <>
        <Wrapper>
            <CsLeftHero>
                <CsContentLeft>
                    <Text>INTRODUCING CLOUDBASE</Text>
                    <Text>Pioneer DEX Aggregator on BASE <br/>
                        All-in-One Platform
                    </Text>
                    <Text>An all-inclusive Defi platform for BASE</Text>
                </CsContentLeft>
                <Flex justifyContent="flex-start" width="100%">
                    <CsLink target='_blank' href="https://whitepaper.cloudbase.finance">WHITEPAPER</CsLink>
                    <CsLink href="/swap">TRADE NOW</CsLink>
                </Flex>
            </CsLeftHero>
            <CsRightImg>
              {
                windownSize > 600 ? (
                  <img src='/images/home/hero_images.png?version=1.1' alt=''/>
                ) : (
                  <img src='/images/home/hero-mobile.png?version=1.2' alt=''/>
                )
              }
            </CsRightImg>
        </Wrapper>
        <CsScroll>
          <img src='/images/base_img/scroll-icon.png?version=1.1' alt=''/>
          <p>SCROLL TO EXPLORE</p>
        </CsScroll>
        </>
  )
}

export default Section1
