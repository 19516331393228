import React from 'react'
import styled from 'styled-components'
import { Flex, Text } from '@pancakeswap/uikit'
import GetWindowSize from '@pancakeswap/uikit/src/util/getWindowSize'
import { useAccount } from 'wagmi'
import useAuth from 'hooks/useAuth'
import { useSessionChainId } from 'hooks/useSessionChainId'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { Container, CsLink, CustomButtonConnectWallet } from '../styles'

const Section3 = () => {
    const windownSize = GetWindowSize();
    const { isConnected } = useAccount()
    const { logout } = useAuth()
    const [, setSessionChainId] = useSessionChainId()
    const { chainId } = useActiveWeb3React()
    return (
        <Container>
            <WrapperSection>
                <CsLeft>
                    <CsContentLeft>
                        <Text> <span style={{ color: '#62FFD9' }}>Explore</span> opportunities from BASE ecosystem</Text>
                        <Text>Automated and fully-audited trading mechanism in an understandable interface.</Text>
                    </CsContentLeft>
                    <CsWrapperLink justifyContent="flex-start" width="100%">
                        {!isConnected && <CustomButtonConnectWallet>Connect wallet</CustomButtonConnectWallet>}
                        {isConnected && (
                            <CustomButtonConnectWallet
                                onClick={() =>
                                    logout().then(() => {
                                        setSessionChainId(chainId)
                                    })
                                }
                            >
                                Disconnect Wallet
                            </CustomButtonConnectWallet>
                        )}
                        <CsLink href="/swap">TRADE NOW</CsLink>
                    </CsWrapperLink>
                </CsLeft>
                <CsRight>
                    <img src='/images/base_img/section3.png?version=1.1' alt='' />
                </CsRight>
            </WrapperSection>
        </Container>
    )
}

const CsWrapperLink = styled(Flex)`
     @media screen and (max-width: 768px){
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
`

const CsLeft = styled(Flex)`
    flex-direction: column;
    @media screen and (max-width: 768px){
        max-width: 75%;
    }
`

const CsRight = styled(Flex)`
  /* width: 50%; */
  @media screen and (max-width: 768px){
        max-width: 25%;
    }
  img{
    width: 100%;
    max-width: 718px;
    max-height: 718px;
  }
`
const WrapperSection = styled(Flex)`
  align-items: center;
  justify-content: space-around;
  margin: 50px 0;
  max-width: 1320px;
  padding-left: 88px;
  @media screen and (max-width: 768px){
    margin: 0;
    padding: 0;
  }
`
const CsContentLeft = styled(Flex)`
    flex-direction: column;
    align-items: left;
    margin-bottom: 20px;
    @media screen and (max-width: 768px){
      margin-bottom: 10px;
    }
    ${Text}{
      color: ${({ theme }) => theme.colors.white};
        &:first-child{
          font-size: 48px;
          font-weight: 900;
          @media screen and (max-width: 768px){
            font-size: 24px;
          }
        }
        &:nth-child(2){
          font-size: 18px;
          letter-spacing: 0.05em;
          max-width: 465px;
          color: ${({ theme }) => theme.colors.disable};
          @media screen and (max-width: 768px){
            font-size: 13px;
            max-width: 100%;
          }
        };
    }
`
export default Section3