import { Button, Flex, Link, Text } from '@pancakeswap/uikit'
import React from 'react'
import styled from 'styled-components'
import { Container } from '../styles'

const Section7 = () => {
    return (
        <>
            <CsSupportChains>
                <div>
                    <h4>Support Chains</h4>
                    {/* <p>{`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s`}</p> */}
                </div>
                <div>
                    <img src="images/base_img/group-chains.png" alt="" />
                </div>
            </CsSupportChains>
            <SectionContainer>
                <Wrapper>
                    <CsImgMain src='/images/base_img/section9.png?version=1.3' alt='' />
                    <WrapContent>
                        <Flex flexDirection="column" justifyContent="center">
                            <Text>Join us now</Text>
                            <Text>Read our Whitepaper.</Text>
                            <CsLink href='#'>
                                <CsButton>JOIN THE COMMUNITY</CsButton>
                            </CsLink>
                        </Flex>
                    </WrapContent>
                </Wrapper>
            </SectionContainer>
        </>
    )
}



const CsSupportChains = styled.div`
    max-width: 1320px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 120px;
    margin-top: 186px;
    @media screen and (max-width: 1024px){
        grid-template-columns: repeat(1, minmax(0, 1fr));
        margin-top: 40px;
        gap: 24px;
    }
    h4{
        color: #FFF;
        font-size: 60px;
        font-style: normal;
        font-weight: 900;
        line-height: 88px;
        @media screen and (max-width: 1024px){
            font-size: 24px;
            text-align: center;
            line-height: normal;
        }
    }
    p{
        color: #ffff;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        margin-top: 18px;
        line-height: 36px;
        @media screen and (max-width: 1024px){
            font-size: 12px;
            text-align: center;
            line-height: normal;
        }
    }
    img{
        position: relative;
        bottom: 135px;
        @media screen and (max-width: 1024px){
            bottom: 0;
        }
    }
`
const Wrapper = styled(Flex)`
    width: 100%;
    margin-bottom: 100px;
    justify-content: center;
    position: relative;
    height: 100%;
    z-index: 1;
`

const CsImgMain = styled.img`
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    z-index: 0;
    margin-left: 70px;
    @media screen and (max-width: 1024px){
        width: 100%;
        height: 100%;
        max-width: 100%;
        left: 0;
        right: 0;
        z-index: 0;
        margin-left: 0;
    }
`

const WrapContent = styled(Flex)`
    padding-left: 100px;
    padding-bottom: 160px;
    left: 50%;
    top: 50%;
    right: 50%;
    width: 550px;
    height: 100px;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    position: absolute;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 768px){
        padding-left: 0px;
    }
    ${Text}{
        letter-spacing: -0.02em;
        text-align: center;
        color: ${({ theme }) => theme.colors.white};
        &:first-child{
            font-size: 60px;
            font-weight:700;
            @media screen and (max-width: 768px){
                font-size: 24px;
            }
        }
        &:nth-child(2){
            font-size: 24px;
            @media screen and (max-width: 768px){
                font-size: 12px;
                margin-top: 12px;
            }
        }
    }
`
const CsLink = styled(Link)`
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    width: 100%;

    &:hover{
        text-decoration:none;
    }
`
const CsButton = styled(Button)`
    border: 1px solid ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.disable};
    @media screen and (max-width: 768px){
        font-size: 12px;
    }
`
const SectionContainer = styled(Container)`
    bottom: 290px;
    position: relative;
    height: 550px;
    width: 100%;
    @media screen and (max-width: 1024px){
        bottom: 0;
        height: 100%;
        }
    img{
        height: 230%;
        @media screen and (max-width: 1024px){
            height: 100%;
        }
    }
    ${WrapContent}
    {
        top: 630px;
        @media screen and (max-width: 1024px){
            top: 50%;
        }

    }
`
export default Section7