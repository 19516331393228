import React from 'react'
import styled from 'styled-components'
import { Button, Flex, Text } from '@pancakeswap/uikit'
import { Container, CsLink } from '../styles'

const Section4 = () => {
    return (
        <Container>
            <WrapperSection>
                <CsLeft>
                    <img src='/images/base_img/section4.png?version=1.1' alt='' />
                </CsLeft>
                <CsRight>
                    <CsContentLeft>
                        <Text> <span style={{ color: '#62FFD9' }}>Connect</span> to the next <br /> journey on BASE</Text>
                        <Text mt="1rem">Exploit the values accross networks.</Text>
                    </CsContentLeft>
                    <Flex justifyContent="flex-start" width="100%">
                        <CsButton>launch app</CsButton>
                        <CsLink href="#">Read the docs</CsLink>
                    </Flex>
                </CsRight>
            </WrapperSection>
        </Container>
    )
}
const CsLeft = styled(Flex)`
  width: 40%;
  @media screen and (max-width: 760px){
    width: 25%;
  }
  img{
    width: 100%;
    max-width: 718px;
    max-height: 718px;
  }
`

const CsRight = styled(Flex)`
  flex-direction: column;
  width: 60%;
  @media screen and (max-width: 760px){
    width: 75%;
  }
`
const WrapperSection = styled(Flex)`
  align-items: center;
  justify-content: space-around;
  margin: 50px 0 100px;
  padding: 0 20px;
  position: relative;
  right: 10px;
  gap: 215px;
  @media screen and (max-width: 768px){
    margin: 60px 0;
    padding: 0;
    right: 0;
    gap: 0;
  }

`
const CsContentLeft = styled(Flex)`
    flex-direction: column;
    align-items: left;
    margin-bottom: 20px;
    @media screen and (max-width: 768px){
      margin-bottom: 10px;
    }
    ${Text}{
      color: ${({ theme }) => theme.colors.white};
        &:first-child{
          font-size: 48px;
          font-weight: 900;
          @media screen and (max-width: 768px){
            font-size: 24px;
          }
        }
        &:nth-child(2){
          font-size: 18px;
          letter-spacing: 0.05em;
          color: ${({ theme }) => theme.colors.disable};
          @media screen and (max-width: 768px){
            font-size: 13px;
          }
        };
    }
`
const CsButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.disable};
  font-size: 14px;
  height: 40px;
  text-transform:uppercase;
  @media screen and (max-width: 768px){
        font-size: 10px;
        height: 32px;
    }
`
export default Section4